body {
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
    background-color:transparent;
  }
  * {
    transition: all 0.3s ease-in-out;
  }
  
  .container {
    clear: both;
    overflow: auto;
  }
  
  nav {
    float: right;
  }
  
  .logo img {
    float: left;
  }
  
  ul li {
    display: inline-block;
    padding: 10px;
    font-size: 20px;
    font-family: raleway;
  }
  
  ul li:hover {
    color: #0C0;
  }
  